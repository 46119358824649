import axios from "axios";
import React from "react";
import { useState,useEffect} from "react";
import {
  Button,
  FormControl,
  FormLabel,
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import { styled } from "@mui/system";
import {
  GridColDef,
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingModal from "../widgets/LoadingModal";
import ErrorModal from "../widgets/ErrorModal";
import Papa from "papaparse"
import SuccessModal from "../widgets/SuccessModal";
import * as XLSX from 'xlsx';
import { ComposedChart, Area,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City");



const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const formStyle = {
  padding: "1em",
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const valueFormatter = (value) => 
  `$ ${Number(value.value).toFixed(2)}`;
  
const columns = [
  { field: "fecha", headerName: "Fecha", width: 120 },
  { field: "contraparte", headerName: "Contraparte", width: 100 },
  { field:  "hora_1",  type:'number',valueFormatter,  headerName: "Hora 1", width: 85 },
  { field:  "hora_2",  type:'number',valueFormatter,  headerName: "Hora 2", width: 85 },
  { field:  "hora_3",  type:'number',valueFormatter,  headerName: "Hora 3", width: 85 },
  { field:  "hora_4",  type:'number',valueFormatter,  headerName: "Hora 4", width: 85 },
  { field:  "hora_5",  type:'number',valueFormatter,  headerName: "Hora 5", width: 85 },
  { field:  "hora_6",  type:'number',valueFormatter,  headerName: "Hora 6", width: 85 },
  { field:  "hora_7",  type:'number',valueFormatter,  headerName: "Hora 7", width: 85 },
  { field:  "hora_8",  type:'number',valueFormatter,  headerName: "Hora 8", width: 85 },
  { field:  "hora_9",  type:'number',valueFormatter,  headerName: "Hora 9", width: 85 },
  { field:  "hora_10", type:'number',valueFormatter,   headerName: "Hora 10", width: 85 },
  { field:  "hora_11", type:'number',valueFormatter,   headerName: "Hora 11", width: 85 },
  { field:  "hora_12", type:'number',valueFormatter,   headerName: "Hora 12", width: 85 },
  { field:  "hora_13", type:'number',valueFormatter,   headerName: "Hora 13", width: 85 },
  { field:  "hora_14", type:'number',valueFormatter,   headerName: "Hora 14", width: 85 },
  { field:  "hora_15", type:'number',valueFormatter,   headerName: "Hora 15", width: 85 },
  { field:  "hora_16", type:'number',valueFormatter,   headerName: "Hora 16", width: 85 },
  { field:  "hora_17", type:'number',valueFormatter,   headerName: "Hora 17", width: 85 },
  { field:  "hora_18", type:'number',valueFormatter,   headerName: "Hora 18", width: 85 },
  { field:  "hora_19", type:'number',valueFormatter,   headerName: "Hora 19", width: 85 },
  { field:  "hora_20", type:'number',valueFormatter,   headerName: "Hora 20", width: 85 },
  { field:  "hora_21", type:'number',valueFormatter,   headerName: "Hora 21", width: 85 },
  { field:  "hora_22", type:'number',valueFormatter,   headerName: "Hora 22", width: 85 },
  { field:  "hora_23", type:'number',valueFormatter,   headerName: "Hora 23", width: 85 },
  { field:  "hora_24", type:'number',valueFormatter,   headerName: "Hora 24", width: 85 },
];

const columns_MEREL506 = [
  { field: "fecha", headerName: "Fecha", width: 120 },
  { field: "hora", headerName: "Hora", width: 120 },
  { field: "MEREL506", type:'number',valueFormatter, headerName: "Pronostico", width: 120 },
  { field: "mape_MEREL506", type:'number',valueFormatter, headerName: "Mape", width: 120 },
  { field: "poe", type:'number',valueFormatter, headerName: "POE", width: 120 },
  { field: "resta_MEREL506", type:'number',valueFormatter, headerName: "POE - Pronostico", width: 120 },
]

const columns_COMCOMEL_504 = [
  { field: "fecha", headerName: "Fecha", width: 120 },
  { field: "hora", headerName: "Hora", width: 120 },
  { field: "COMCOMEL_504", type:'number',valueFormatter, headerName: "Pronostico", width: 120 },
  { field: "mape_COMCOMEL_504", type:'number',valueFormatter, headerName: "Mape", width: 120 },
  { field: "poe", type:'number',valueFormatter, headerName: "POE", width: 120 },
  { field: "resta_COMCOMEL_504", type:'number',valueFormatter, headerName: "POE - Pronostico", width: 120 },
]

const columns_MEL_502 = [
  { field: "fecha", headerName: "Fecha", width: 120 },
  { field: "hora", headerName: "Hora", width: 120 },
  { field: "MEL_502", type:'number',valueFormatter, headerName: "Pronostico", width: 120 },
  { field: "mape_MEL_502", type:'number',valueFormatter, headerName: "Mape", width: 120 },
  { field: "poe", type:'number',valueFormatter, headerName: "POE", width: 120 },
  { field: "resta_MEL_502", type:'number',valueFormatter, headerName: "POE - Pronostico", width: 120 },
]

const columns_BORAX = [
  { field: "fecha", headerName: "Fecha", width: 120 },
  { field: "hora", headerName: "Hora", width: 120 },
  { field: "BORAX", type:'number',valueFormatter,  headerName: "Pronostico", width: 120 },
  { field: "mape_BORAX", type:'number',valueFormatter, headerName: "Mape", width: 120 },
  { field: "poe", type:'number',valueFormatter, headerName: "POE", width: 120 },
  { field: "resta_BORAX", type:'number',valueFormatter, headerName: "POE - Pronostico", width: 120 }, 
]

export default function MapeContraparte({ auth }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [messageData, setMessageData] = useState([])
  const handleClose = () => setError(false);
  const handleCloseS = () => setSuccess(false);
  const [file, setFile] = useState([]);
  const [values, setValues] = useState({
    dateI : dayjs(new Date()),
    "fecha-inicio":dayjs().subtract(6,"hours").format('YYYY-MM-DD'),
  });
  const [data, setData] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const url = `${process.env.REACT_APP_URL_BACK}`;


  useEffect(() => {
     get()
  }, []);

  async function get() {
    setLoad(true)
    const getResponse = await post("servicio/mape_contraparte/get",{'fecha':values['fecha-inicio']})
      if(getResponse["Status"] == "Ok"){
          setLoad(false)
          setData(getResponse["Data"])
          setDataChart(getResponse["chart_data"])
      }else{
        setLoad(false)
        setMessageData(getResponse["Data"])
        setError(true)
      }
   } 


  function parse(){
    const reader = new FileReader();
    
    return new Promise((resolve, reject) => {
      //Resolver
      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result,{type:'binary'});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet)
        resolve(sheetData)
      };
      //Reject
      reader.onerror = () => {
        reject('Error al leer el archivo')
      }
      //Action
      reader.readAsArrayBuffer(file)
    })
  }

  async function submit(event){
      event.preventDefault()
      setLoad(true)      
      var data = []
      await parse(file).then((d) =>{ data = d })
      var dic = {}
      Object.keys(data[0]).forEach(element => {
        if(element.toLowerCase() != 'hora'){
          dic[element] = {}
        }
      })
      data.forEach(value => {
        Object.keys(dic).forEach(key => {
          dic[key][value['Hora']] = value[key]
        })
      });
      
      var lista_data = [] 
      Object.keys(dic).forEach(element => {
          const tmp = { 
            "fecha": values['fecha-inicio'],
            "contraparte": element,
            "h_1": dic[element]['1'],
            "h_2": dic[element]['2'],
            "h_3": dic[element]['3'],
            "h_4": dic[element]['4'],
            "h_5": dic[element]['5'],
            "h_6": dic[element]['6'],
            "h_7": dic[element]['7'],
            "h_8": dic[element]['8'],
            "h_9": dic[element]['9'],
            "h_10": dic[element]['10'],
            "h_11": dic[element]['11'],
            "h_12": dic[element]['12'],
            "h_13": dic[element]['13'],
            "h_14": dic[element]['14'],
            "h_15": dic[element]['15'],
            "h_16": dic[element]['16'],
            "h_17": dic[element]['17'],
            "h_18": dic[element]['18'],
            "h_19": dic[element]['19'],
            "h_20": dic[element]['20'],
            "h_21": dic[element]['21'],
            "h_22": dic[element]['22'],
            "h_23": dic[element]['23'],
            "h_24": dic[element]['24'],
        }
        lista_data.push(tmp)
      });

      const response = await post("servicio/mape_contraparte/upload",lista_data)
      
      if (response["Status"] == "Ok"){
        
        const getResponse = await post("servicio/mape_contraparte/get",{'fecha':values['fecha-inicio']})
        if(getResponse["Status"] == "Ok"){
            setLoad(false)
            setMessageData(response["Data"])
            setData(getResponse["Data"])
            setDataChart(getResponse["chart_data"])
            setSuccess(true)
        }else{
          setLoad(false)
          setMessageData(response["Data"])
          setError(true)
        }
      }
      else{
        setLoad(false)
        setMessageData(response["Data"])
        setError(true)
      }
      
    }


  const post = async(endpoint,info) =>{
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "token " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await axios
    .post(url + endpoint, info, config)
    .then((response) => {
        return response.data
    })
    .catch((e) => {
      console.log(e)
      return e;
    });
    return response
  }

  function onChangeCal(event, type) {
    let v = values;
    v[type] = dayjs(event.$d.toISOString()).format("YYYY-MM-DD");
    
    if(type == "fecha-inicio")
      v['dateI'] = dayjs(event.$d.toISOString())
    if(type == "fecha-fin")
      v['dateF'] = dayjs(event.$d.toISOString())
    setValues(v);
    get()
  }


  function Form() {
    return (
      <form style={{ display: "flex", alignItems: "center" }} onSubmit={submit}>
        <FormControl style={formStyle}>
          <FormLabel>Fecha del archivo:</FormLabel>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="es-mx"
          >
            <DatePicker
              defaultValue={values.dateI}
              format="YYYY-MM-DD"
              onChange={(e) => onChangeCal(e, "fecha-inicio")}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl style={formStyle}>
          <FormLabel>Archivo CSV:</FormLabel>
          <Button
            component= "label"
            role = {undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{ height: "50px" }}
          >
            Selecciona tu archivo
            <VisuallyHiddenInput type="file" onChange={(e) => {setFile(e.target.files[0]);} }/>
          </Button>
        </FormControl>

        <FormControl style={formStyle}>
          <br/>
          <Button
            color="primary"
            type="submit"
            variant="outlined"
            sx={{ height: "50px" }}
          >
            Subir archivo
          </Button>
        </FormControl>
        
      </form>
    );
  }

  function TableGen() {
    return (
      <div style={{ height: 375, width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  function Table(columns) {
    var col = []
    if (columns['columns'] == 'BORAX'){
      col = columns_BORAX
    }
    else if (columns['columns'] == 'COMCOMEL_504'){
      col = columns_COMCOMEL_504
    }
    else if (columns['columns'] == 'MEREL506'){
      col = columns_MEREL506
    }
    else if (columns['columns'] == 'MEL_502'){
      col = columns_MEL_502
    }
    return (
      <div style={{ height: 375, width: "50%" }}>
        <DataGrid
          rows={dataChart}
          columns={col}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    );
  }

  function Chart1() {
    return (
      <div style={{ height: 500, width: "50%" }}>
        <ResponsiveContainer height={500}>
          <ComposedChart
          width={500}
          height={300}
          data={dataChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey={"hora"}/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dot={false} dataKey="MEREL506" stroke="#0000FF"/>
            <Line type="monotone" dot={false} dataKey="poe" stroke="#FF0000"/>
            <Area type="monotone" dataKey="area_MEREL506" fill="#008000" stroke="none"/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )

  }

  function Chart2() {
    return (
      <div style={{ height: 500, width: "50%" }}>
        <ResponsiveContainer height={500}>
          <ComposedChart
          width={500}
          height={300}
          data={dataChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey={"hora"}/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dot={false} dataKey="COMCOMEL_504" stroke="#0000FF"/>
            <Line type="monotone" dot={false} dataKey="poe" stroke="#FF0000"/>
            <Area type="monotone" dataKey="area_COMCOMEL_504" fill="#008000" stroke="none"/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )

  }

  function Chart3() {
    return (
      <div style={{ height: 500, width: "50%" }}>
        <ResponsiveContainer height={500}>
          <ComposedChart
          width={500}
          height={300}
          data={dataChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey={"hora"}/>
            <YAxis/>
            <Tooltip/>
            <Legend/>

            <Line type="monotone" dot={false} dataKey="MEL_502" stroke="#0000FF"/>
            <Line type="monotone" dot={false} dataKey="poe" stroke="#FF0000"/>
            <Area type="monotone" dataKey="area_MEL_502" fill="#008000" stroke="none"/>

          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )

  }

  function Chart4() {
    return (
      <div style={{ height: 500, width: "50%" }}>
        <ResponsiveContainer height={500}>
          <ComposedChart
          width={500}
          height={300}
          data={dataChart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey={"hora"}/>
            <YAxis/>
            <Tooltip/>
            <Legend/>
            <Line type="monotone" dot={false} dataKey="BORAX" stroke="#0000FF"/>
            <Line type="monotone" dot={false} dataKey="poe" stroke="#FF0000"/>
            <Area type="monotone" dataKey="area_BORAX" fill="#008000" stroke="none"/>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }


  return (
    <>
      {auth ? (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Mape Contraparte</h1>
            </Box>
            
            <Form/>
            
            <TableGen/>

            <Box style={{ display:"flex" }} >
              <Table columns='MEREL506' />
              <Chart1/>
            </Box>

            <Box style={{ display:"flex" }} >
              <Table columns='COMCOMEL_504' />
              <Chart2/>
            </Box>

            <Box style={{ display:"flex" }} >
              <Table columns='MEL_502' />
              <Chart3/>
            </Box>

            <Box style={{ display:"flex" }} >
              <Table columns='BORAX' />
              <Chart4/>
            </Box>

            <LoadingModal isLoad={load} />
            <ErrorModal open={error} close={handleClose} data={messageData}/>
            <SuccessModal open={success} close={handleCloseS} data={messageData}/>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              margin: "10px",
              padding: "0 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link href="/">
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <h1>Inicia Sesión</h1>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
